export abstract class CoderOneRoute {
    public static readonly AdjudicatedGames = "/adjudicated-games";
    public static readonly BlogIndex = "/blog";
    public static readonly UsersIndex = "/users";
    public static readonly Bomberland = "/bomberland";
    public static readonly Dashboard = "/dashboard";
    public static readonly DocumentationGettingStarted = "/docs/getting-started";
    public static readonly DocumentationSubmissionInstructions = "/docs/submission-instructions";
    public static readonly DocumentationIndex = "/docs";
    public static readonly Game = "/game";
    public static readonly JobQueue = "/job-queue";
    public static readonly History = "/history";
    public static readonly Home = "/";
    public static readonly Leaderboard = "/leaderboard";
    public static readonly LeaderboardV3 = "/leaderboard-v3";
    public static readonly Login = "/login";
    public static readonly Pricing = "/pricing";
    public static readonly Account = "/account";
    public static readonly Replays = "/replays";
    public static readonly Redeem = "/redeem-ticket";
    public static readonly Signup = "/signup";
    public static readonly Submissions = "/submissions";
    public static readonly Team = "/team";
    public static readonly AboutUs = "/about-us";
    public static readonly EntryTicket = "/entry-ticket";
    public static readonly BlogSeasonOneRecap = "/blog/2020-aisports-challenge-recap";
    public static readonly BlogSeasonTwoRecap = "/blog/2021-aisports-challenge-recap";
    public static readonly BlogSeasonFourRecap = "/blog/coder-one-season-4-ai-programming-tournament-recap";
    public static readonly GettingStarted = "/docs/getting-started";
    public static readonly ProfileRoute = (username: string) => `${CoderOneRoute.UsersIndex}/${username}`;
    public static readonly GameInstanceRoute = (connectionString: string): string =>
        `${CoderOneRoute.Game}/?uri=${encodeURIComponent(connectionString)}`;

    public static readonly SubmissionRoute = (submissionId: string): string => `${CoderOneRoute.Submissions}/${submissionId}`;
}
